<template>
  <div
    class="QuizAndGameLayout"
    :class="{ showResults }">
    <div class="header">
      <ProgressBar :progress="progress" />

      <div class="headerContent">
        <Lives v-if="showLives" :remaining="remainingLives" />
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in">
      <div
        v-if="showResults"
        class="results">
        <slot
          name="result"
          :s="session" />
      </div>
      <QuizHost
        v-else-if="session"
        :session="session" />
    </transition>

    <div
      class="backButton --global-clickable"
      @click="$emit('request-exit')">
      <svgicon icon="close" />
    </div>
  </div>
</template>

<script>
import QuizHost from '@/modules/quiz/components/QuizHost';
import ProgressBar from '@/components/ProgressBar';
import Lives from '@/modules/games-shared/components/Lives';

export default {
    components: { QuizHost, ProgressBar, Lives },
    props: {
        session: {
            type: Object
        },
        showLives: {
            type: Boolean,
            default: true
        },
        externalResult: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            remainingLives: 0,
            progress: 0,
            showResults: false
        };
    },
    mounted() {
        if (this.session) {
            this.start();
        }
        this.$watch('session', () => {
            if (this.showResults) {
                this.redo();
            } else {
                this.start();
            }
        });
    },
    methods: {
        start() {
            this.session.on('end', this.onEnd);
            this.session.on('update', this.onUpdate);
            this.onUpdate();
        },
        onEnd() {
            this.session.off('end', this.onEnd);
            this.session.off('update', this.onUpdate);
            this.$emit('on-result');
            if (!this.externalResult) {
                this.showResults = true;
            }
        },
        onUpdate() {
            this.remainingLives = this.session.lives;
            this.progress = this.session.correctCount / this.session.totalCount;
        },
        redo() {
            this.showResults = false;
            this.start();
        }
    }
};
</script>

<style lang="scss" scoped>
.QuizAndGameLayout {
    color: white;
    display: flex;
    flex-direction: column;

    &.showResults {
        .Lives,
        .ProgressBar {
            visibility: hidden;
        }
    }
}
.header {
    flex-shrink: 0;
}
.QuizHost {
    flex: 1;
}
.headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    height: 3em;
}

.results {
    position: absolute;
    top: 0em;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > * {
        max-height: calc(100% - 2em);
    }
}

.backButton {
    $size: 3em;
    background-color: rgba(white, 0.5);
    height: $size;
    width: $size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1em;
    right: 1em;
    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: rgba(black, 0.9);
    }
}

$transitionDuration: 0.5s;
.fade-enter-active,
.fade-leave-active {
    transition: opacity $transitionDuration;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
