<template>
  <div class="QuizView">
    <transition
      name="slideUp"
      mode="out-in">
      <QuizAndGameLayout
        v-if="playing"
        :key="sessionCount"
        :session="session"
        :show-lives="false"
        class="Screen"
        @on-result="showResult()"
        @request-exit="showResult()" />
      <div
        v-else
        key="resultContainer"
        class="Screen resultContainer">
        <QuizResult
          :completed="checkCompleted()"
          :title="success ? $t('success.title') : $t('fail.title')"
          :subtitle="success ? $t('success.subtitle') : $t('fail.subtitle')"
          :text="success && !autoContinueOnSuccess ? $t('continueText') : ''"
          :score="score"
          :buttons="quizResultButtons"
          @action="handleQuizResultAction" />
      </div>
    </transition>
  </div>
</template>

<translations>
  success:
    title: 'Great Job'
    title_no: 'Bra jobbet'
    subtitle: 'You have successfully completed the quiz'
    subtitle_no: 'Du har fullført quizzen'

  fail:
    title: 'Failed Quiz'
    title_no: 'Quiz feilet'
    subtitle: 'That was a good try. Better luck next time'
    subtitle_no: 'Et godt forsøk. Det går bedre neste gang'

  continueButton: 'Continue'
  continueButton_no: 'Fortsett'
  continueText: 'Continue to memorize the details.'
  continueText_no: 'Fortsett med å memorere detaljene.'

  backToMemo: 'Back to memo'
  backToMemo_no: 'Tilbake til memo'
  redoQuiz: 'Redo Quiz'
  redoQuiz_no: 'Quiz på nytt'
</translations>

<script>
import QuizAndGameLayout from '../components/QuizAndGameLayout';
import QuizSession from '@/modules/quiz/quiz-session';
import quizHelper from '@/modules/course/quiz-helper';
import QuizResult from '../components/QuizResult';

export default {
    components: { QuizAndGameLayout, QuizResult },
    props: {
        stepId: {
            type: String,
            required: true
        },
        activityName: {
            type: String,
            required: true
        },
        playing: {
            type: Boolean,
            default: false
        }
    },
    inject: ['theme'],
    data() {
        return {
            session: null,
            sessionCount: 0
        };
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        activity() {
            return this.step[this.activityName];
        },
        success() {
            return this.session && this.session.totalCount === this.session.correctCount;
        },
        score() {
            return (this.session && this.session.correctCount / this.session.totalCount) || 0;
        },
        quizResultButtons() {
            if (this.success && this.autoContinueOnSuccess) {
                return null;
            } else if (this.success) {
                return [
                    {
                        label: this.$t('continueButton'),
                        action: 'next'
                    }
                ];
            } else {
                return [
                    {
                        label: this.$t('redoQuiz'),
                        action: 'redo'
                    },
                    {
                        label: this.$t('backToMemo'),
                        action: 'memo'
                    }
                ];
            }
        }
    },
    created() {
        this.autoContinueOnSuccess = !(this.activityName === 'quiz' && this.step.memorize2);
        if (this.playing) {
            this.start();
        }
    },
    methods: {
        async start() {
            this.sessionCount += 1;
            const dataProvider = await quizHelper.createQuestionProvider(this.activity, { stepId: this.stepId });
            this.session = new QuizSession(dataProvider, { lives: 1 });
        },
        checkCompleted() {
            const activity = this.$store.state.moduleCourse.steps[this.stepId][this.activityName];
            return activity.progress === 1;
        },
        handleQuizResultAction(action) {
            this[action]();
        },
        memo() {
            this.$router.replace({
                name: 'Course-ActivityTransition',
                params: {
                    activityName: { quiz: 'memorize', quiz2: 'memorize2' }[this.activityName]
                }
            });
        },
        redo() {
            this.$router.replace({
                name: 'Course-QuizView-Play',
                params: {
                    activityName: this.activityName
                }
            });
            this.start();
        },
        next() {
            const activityIndex = this.step.activityNames.indexOf(this.activityName);
            const nextActivityName = this.step.activityNames[activityIndex + 1];
            if (nextActivityName) {
                this.$router.replace({
                    name: 'Course-ActivityTransition',
                    params: {
                        activityName: nextActivityName
                    }
                });
            } else {
                this.$router.replace({
                    name: 'Course-StepEnd'
                });
            }
        },
        showResult() {
            this.$router.replace({
                name: 'Course-QuizView',
                params: {
                    activityName: this.activityName
                }
            });

            if (this.success) {
                this.$store.dispatch('moduleCourse/markStepQuizAsDone', {
                    stepId: this.stepId,
                    activityName: this.activityName
                });

                if (this.autoContinueOnSuccess) {
                    setTimeout(() => {
                        this.next();
                    }, 3000);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.QuizView {
}
.Screen {
    height: 100%;

    $distance: 2em;
    $transitionDuration: 0.3s;
    &.slideUp-enter-active,
    &.slideUp-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }

    &.QuizAndGameLayout {
        &.slideUp-enter {
            transform: translate3d(0, $distance, 0);
            opacity: 0;
        }
        &.slideUp-enter-to {
            transform: translate3d(0%, 0, 0);
        }
        &.slideUp-leave-to {
            transform: translate3d(0, $distance, 0);
            opacity: 0;
        }
    }
    &.resultContainer {
        &.slideUp-enter {
            transform: translate3d(0, -$distance, 0);
            opacity: 0;
        }
        &.slideUp-enter-to {
            transform: translate3d(0%, 0, 0);
        }
        &.slideUp-leave-to {
            transform: translate3d(0, -$distance, 0);
            opacity: 0;
        }
    }
}
.resultContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    & > * {
        max-height: calc(100% - 2em);
    }
}
</style>
